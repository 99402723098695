export const postToUrl = (url, parameters) => {
  const form = document.createElement('form');

  form.method = 'POST';
  form.action = `${process.env.VUE_APP_ROOT_API}/${url}`;

  for (const [key, value] of Object.entries(parameters)) {
    const element = document.createElement('input');
    element.name = key;
    element.value = value;
    form.appendChild(element);
  }

  document.body.appendChild(form);
  form.submit();
  form.remove();
}
