<template>
  <v-overlay>
    <VProgressCircular indeterminate size="64"/>
  </v-overlay>
</template>

<script>
import { postToUrl } from '@/application/util/postToUrl.js';
import instance from '@/api/implementation/app';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'Oauth',
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      bearerToken: 'authorisation/getToken',
    }),
    ...mapState('oauth', ['clientId', 'authToken', 'requestId']),
  },
  async created() {
    this.setOauthFromQuery();
    try {
      instance.interceptors.response.eject(0);
      await instance.get('profile');
      this.redirectToOauth();
    } catch {
      this.$router.push({
        name: 'login',
        query: { redirectUrl: 'oauth' },
      });
    }
  },
  methods: {
    ...mapMutations({
      setOauthInformation: 'oauth/setOauthInformation',
      clearOauthInformation: 'oauth/clearOauthInformation',
    }),
    setOauthFromQuery() {
      if (!this.$route.query.client_id) return;
      if (!this.$route.query.auth_token) return;
      if (!this.$route.query.request_id) return;

      this.setOauthInformation({
        clientId: this.$route.query.client_id,
        authToken: this.$route.query.auth_token,
        requestId: this.$route.query.request_id,
      });
    },
    redirectToOauth() {
      const clientId = this.clientId;
      const authToken = this.authToken;
      const requestId = this.requestId;
      const bearerToken = this.bearerToken;

      this.clearOauthInformation();

      postToUrl('oauth/authorize', {
        client_id: clientId,
        auth_token: authToken,
        request_id: requestId,
        bearer_token: bearerToken,
      });
    },
  },
};
</script>
